<template>
  <div style="width: 100%; height: 100%; background-color: #f6f6f6">
    <div style="border-radius: 0.4rem">
      <div>
        <div style="height: 2.8rem">
          <van-image
            fit="cover"
            height="5rem"
            style="margin-left: 0.5rem; margin-right: 0.5rem"
            :src="res.head_img"
          />
        </div>
        <div class="user-header">
          <img :src="res.logo" />
        </div>
        <div class="user-nickname">{{ res.name }}</div>
        <div class="user-nickname">
          <span style="font-weight: 200; color: #4b4b4b; font-size: 0.5rem"
            >集美貌与财富并存</span
          >
        </div>
      </div>
      <div>
        <van-row class="user-data">
          <van-col span="8" class="user-van-col">
            <div class="user-number">
              <b>{{ this.dayOrders }}</b>
            </div>
            <div class="user-title">今日订单</div>
          </van-col>
          <van-col span="8" class="user-van-col">
            <div class="user-number">
              <b>{{ formatPrice(this.dayAmount) }}</b>
            </div>
            <div class="user-title">今日营业额</div>
          </van-col>
          <van-col span="8" class="user-van-col">
            <div class="user-number">
              <b>{{ formatPrice(this.monthAmount) }}</b>
            </div>
            <div class="user-title">当月累计</div>
          </van-col>
        </van-row>
      </div>
      <van-cell-group>
        <van-cell
          icon="records"
          title="订单列表"
          is-link
          to="merchantAllOrder"
        />
        <van-cell icon="points" title="经营报表" is-link to="merchantBizData" />
        <van-cell icon="edit" title="修改密码" is-link to="merchantModifyPwd" />
        <van-cell icon="manager-o" title="退出登录" is-link @click="quit" />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Image } from "vant";
import { statisticsData } from "@/api/merchant";
import { mapState } from "vuex";
import Cookies from "js-cookie";
import router from "@/router/index";

export default {
  name: "MerchantMe",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Image.name]: Image,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      dayOrders: 0,
      dayAmount: 0.0,
      monthAmount: 0.0,
    };
  },
  computed: {
    ...mapState({
      merId: (state) => state.base.merchantId,
      res: (state) => state.base.currentRestaurant,
    }),
  },
  watch: {
    $route(to) {
      if (to.name === "MerchantMe") {
        this.getDayData();
        this.getMonthData();
      }
    },
  },
  mounted() {
    this.getDayData();
    this.getMonthData();
  },
  methods: {
    getDayData() {
      statisticsData({
        mer_id: this.merId,
        data_type: 1,
        pageNumber: 1,
        pageSize: 1,
      })
        .then((res) => {
          this.dayOrders = res.data.orderCount;
          this.dayAmount = res.data.orderAmount;
        })
        .catch(() => {
          this.$toast.fail("查询数据失败");
        });
    },
    getMonthData() {
      statisticsData({
        mer_id: this.merId,
        data_type: 4,
        pageNumber: 1,
        pageSize: 1,
      })
        .then((res) => {
          this.monthAmount = res.data.orderAmount;
        })
        .catch(() => {
          this.$toast.fail("查询数据失败");
        });
    },
    quit() {
      this.$dialog
        .confirm({
          title: "",
          message: "您确定要退出系统吗？",
        })
        .then(() => {
          Cookies.remove("token");
          Cookies.remove("mer_id");
          Cookies.remove("mch");
          this.$store.commit("app/CLEAR_KEEP_ALIVE");
          router.push("/login");
        })
        .catch(() => {
          console.log("我取消了");
        });
    },
    formatPrice(price) {
      return price == undefined ? 0 : (price / 100).toFixed(2);
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  &-poster {
    width: 100%;
    height: 60vw;
    display: block;
    background-color: @mch-color;
    box-shadow: 0 0 0 30rem #efefef;
  }

  &-header {
    display: flex;
    padding-top: 0.5rem;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    img {
      width: 3rem;
      height: 3rem;
      z-index: 2;
      border-radius: 50%;
    }
  }

  &-nickname {
    display: flex;
    padding-top: 0.5rem;
    align-items: center;
    color: #2c2c2c;
    font-size: 0.6rem;
    justify-content: center;
    font-weight: bold;
    background: #fff;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &-group {
    margin-bottom: 0.9rem;
  }

  &-data {
    padding: 0.9rem 0;
    font-size: 0.5rem;
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-shadow: 0 0.05rem #d0d0d0;
  }

  &-number {
    color: #a4a4a4;
    padding: 0 0rem 0.3rem 0rem;
    b {
      color: @mch-color;
      font-size: 0.8rem;
      font-weight: 350;
      display: inline-block;
      line-height: 1rem;
      font-family: Helvetica Neue, Tahoma;
    }
  }
  &-van-col:nth-of-type(2) {
    .user-number {
      b {
        color: #ee3030;
      }
    }
  }
  &-van-col:nth-of-type(3) {
    .user-number {
      b {
        color: @mch-color;
      }
    }
  }

  &-title {
    color: #252525;
    font-size: 0.5rem;
  }
}
.head-img {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.5rem;
  height: 5rem;
  margin-right: 0.5rem;
}
/deep/ .van-cell {
  background: #f6f6f6;
}
</style>
